
import { defineComponent } from 'vue';

import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  components: {
    Slider,
    SliderItem,
  },
});
